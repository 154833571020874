import { ArrowRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  GameSite,
  whichGameSite,
} from '@genteras/core-lib/constants/game-sites.constant';
import { cssMedia } from '@genteras/core-lib/utils/css-utils';
import { BadRequest } from '@tsed/exceptions';
import { Button, Col, Row } from 'antd';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CarouselBanner } from '@/components/common/CarouselBanner';
import GameItem from '@/components/common/GameItem';
import { useLaunchGame } from '@/lib/hooks/useLaunchGame';
import { homeConfig, gameResultConfig } from '@/lib/i18n/configs';
import type { SiteConfig } from '../config';
import config, { Sites } from '../config';
import { urls } from '../constant/urls';
import useGameList from '../lib/hooks/useGameList';
import useUser from '../lib/hooks/useUser';

type Props = {
  /** Add Route props here */
};

const MenuCtn = styled('div')({
  display: 'flex',
  margin: '0 8px 20px 8px',
  gap: 4,
  [cssMedia.xl]: {
    margin: '0 0 20px 0',
  },
});
const GameSection = styled('div')({
  padding: '0 8px 20px 8px',
  color: 'var(--White)',
  [cssMedia.xl]: {
    margin: '0 0 20px 0',
  },
});
const GameCategoryButton = styled(ArrowRightOutlined)({
  fontSize: 24,
});
const GameRow = styled(Row)({
  marginLeft: '0px !important',
  marginRight: '0px !important',
});

export default function HomeIndex(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const { t } = useTranslation();
  const { user, isLoading } = useUser();
  const router = useRouter();
  const { gamesByType } = useGameList();
  const [activeMenuKey, setMenuKey] = useState('casino');
  const { launchGame } = useLaunchGame();

  const menuItems = [
    {
      key: 'casino',
      label: t('common:Casino'),
      types: [
        {
          label: `SA Gaming`,
          type: { gameType: 'live', gameSite: [GameSite.SA] },
          showNavigation: false,
        },
        {
          label: `Evolution`,
          type: { gameType: 'live', gameSite: [GameSite.EVO] },
          showNavigation: false,
        },
        {
          label: t('common:gameType.casino'),
          type: { gameType: 'casino', gameSite: [] },
          showNavigation: false,
        },
        {
          label: t('common:gameType.slot'),
          type: { gameType: 'slot', gameSite: [] },
          showNavigation: true,
        },
      ],
    },
    {
      key: 'sports',
      label: t('common:Sports'),
      types: [],
      onClick: () =>
        launchGame(
          user.gameSiteSettings.find(
            (x) => whichGameSite(x.gameSite) === GameSite.FM
          )?.gameSite ?? GameSite.FM,
          null,
          'sportbook'
        ),
    },
    {
      key: 'others',
      label: t('common:Others'),
      types: [
        {
          label: t('common:gameType.bo'),
          type: { gameType: 'bo', gameSite: [GameSite.BO] },
          showNavigation: false,
        },
        {
          label: t('common:gameType.cockFight'),
          type: { gameType: 'cockFight', gameSite: [GameSite.PC] },
          showNavigation: false,
        },
      ],
    },
  ];

  useEffect(() => {
    if (!isLoading && !user.id) {
      if (window.location.hostname === 'www.aieia.com') {
        router.push(urls.login);
      } else if (window.location.hostname === 'www.aieias.com') {
        router.push(urls.casa98);
      }
    }
  }, [router, user, isLoading]);

  const [_, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });

  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);

  return (
    <>
      <CarouselBanner />
      <div
        className="main-content-container"
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <MenuCtn>
          {menuItems.map((x, idx) => (
            <Button
              key={idx}
              onClick={() => (x.onClick ? x.onClick() : setMenuKey(x.key))}
              className={x.key === activeMenuKey ? 'active' : ''}
            >
              {x.label}
            </Button>
          ))}
        </MenuCtn>

        <Row>
          {menuItems
            .find((x) => x.key === activeMenuKey)
            ?.types.map((x) => {
              if (x.type.gameType === 'live') {
                return (
                  <Col key={'game-' + x.type} span={12}>
                    <GameSection>
                      <Row justify="space-between" align="middle">
                        <h4>{x.label}</h4>
                        {x.showNavigation ? (
                          <GameCategoryButton
                            onClick={() => {
                              router.push(x.type.gameType);
                            }}
                          />
                        ) : null}
                      </Row>
                      <GameRow gutter={[16, 16]}>
                        {(gamesByType[x.type.gameType] ?? [])
                          .filter((game) =>
                            x.type.gameSite.length > 0
                              ? x.type.gameSite.includes(
                                  whichGameSite(game.gameSite) ?? GameSite.SA
                                )
                              : true
                          )
                          .map((game, idx) => {
                            return (
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                key={`game-${x.type}-${idx}`}
                              >
                                <GameItem game={game} />
                                {game.gameType === 'live'
                                  ? t(`gameResult:gameType.${game.name}`)
                                  : null}
                              </Col>
                            );
                          })}
                      </GameRow>
                    </GameSection>
                  </Col>
                );
              } else {
                return (
                  <Col key={'game-' + x.type} span={24}>
                    <GameSection>
                      <Row justify="space-between" align="middle">
                        <h4>{x.label}</h4>
                        {x.showNavigation ? (
                          <GameCategoryButton
                            onClick={() => {
                              router.push(x.type.gameType);
                            }}
                          />
                        ) : null}
                      </Row>
                      <GameRow gutter={[16, 16]}>
                        {(gamesByType[x.type.gameType] ?? []).map(
                          (game, idx) => {
                            return (
                              <Col
                                xs={12}
                                sm={8}
                                md={6}
                                key={`game-${x.type}-${idx}`}
                              >
                                <GameItem game={game} />
                              </Col>
                            );
                          }
                        )}
                      </GameRow>
                    </GameSection>
                  </Col>
                );
              }
            })}
        </Row>
      </div>
    </>
  );
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new BadRequest('locale is missing');
  }
  const i18nNamespaces = [
    ...homeConfig.i18nNamespaces,
    ...gameResultConfig.i18nNamespaces,
  ];

  return {
    props: {
      ...(await serverSideTranslations(locale, i18nNamespaces.slice())),
    },
  };
};
